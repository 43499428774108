import React, { useState } from "react";
import cx from "classnames";
import { useSpring, motion, motionValue } from "framer-motion";

const SpringConfig = {
  damping: 80,
  stiffness: 1250,
};


const VideoPlayer = ({
  src,
  className,
  copy,
  id,
}) => {
  const [playVideo, setPlayVideo] = useState(true);
  const motionHeight = useSpring(50, SpringConfig);
  const borderRadius = motionValue(50);

  let vidPlay = () => {
    let vid = document.getElementById(id);


    setPlayVideo(!playVideo);
    playVideo ? vid.play() : vid.pause()
  }


  return (
    <>
      <div className={cx("video-player", className)}>
        <motion.video
          variants={{
            open: {
              display: "none",
            },
          }}
          animate={playVideo ? "open" : "closed"}
          id={id}
          src={src}
          className="video-player__video"
          playsInline
        />
        <motion.button
          variants={{
            open: {
              width: "100%",
              height: "100%",
              borderRadius: "10px",
            },
            closed: {
              marginLeft: "1rem",
              marginTop: "1rem",
              width: "50px",
              height: "50px",
              borderRadius: "50px",
            },
          }}
          animate={playVideo ? "open" : "closed"}
          style={{
            height: playVideo ? motionHeight : "100%",
            borderRadius: playVideo ? 0 : borderRadius,
          }}
          onClick={vidPlay}
          className={cx("video-player__button", {
            "video-player__button-isOpen ": !!playVideo,
          })}
        />
      </div>
      <p
        style={{
          marginTop: "20px",
          color: "#d1c8fb",
          opacity: !playVideo ? "1" : "1",
        }}
      >
        {copy}
      </p>
    </>
  );
};
console.log(VideoPlayer)

export default VideoPlayer;
