import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import VideoPlayer from "./components/VideoPlayer";
import vogue from "./videos/vouge-china.mp4";
import peterDofound from "./videos/peterdofoundation.mp4";
import peterDonight from "./videos/peterdonight.mp4";
import ssense from "./videos/nonsumerism.mp4";

class App extends Component {


  renderHomeScreen = () => {


    const screen1Style = {
      boxSizing: "border-box",
      width: "100%",
      // height: "100vh",
      overflow: "scrollX",
      // display: "flex",
      // flexDirection: "column",
      // justifyContent: "flex-start",
      // alignItems: "center",
      // padding: "0px 0px 0px 0px",
      backgroundColor:
        "#121212",
      // overflow: "hidden",
      // aspectRatio: "0.351123595505618 / 1",
      padding: "1rem"
    };
    const header = {
      boxSizing: "border-box",
      flexShrink: 0,
      width: "100%",
      height: 23,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      overflow: "visible",
    };
    const stephShiu = {
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
      wordBreak: "break-word",
      overflow: "hidden",
      textShadow: `0px 0px 4px var(--token-b7e49b9d-ddef-4531-98f0-7313ef492051, #d1c8fb) /* {"name":"B"} */`,
      fontFamily: `"Helvetica Neue", sans-serif`,
      color: `var(--token-b7e49b9d-ddef-4531-98f0-7313ef492051, #d1c8fb) /* {"name":"B"} */`,
      fontSize: 12,
      letterSpacing: 17.9,
      lineHeight: 2.1,
      textAlign: "center",
    };

    const aspectRatio = 1.7586206896551724 / 1;
    const height1 = 100 / aspectRatio

    const height2 = 70 / aspectRatio
    const height3 = 100 / aspectRatio
    const height4 = 50 / aspectRatio
    console.log(height1)
    return (
      <>
        <div className="Screen1" style={screen1Style}>
          <header style={header}>
            <h1 style={stephShiu}>STEPH SHIU</h1>
          </header>
          <div className="video1" style={{
            width: "100%",
            height: height1 * 0.85 + "vw",
            position: "relative"
          }}>

            <VideoPlayer id="1"
              src={vogue}
              copy={` VOGUE CHINA FILM "御日华光""Since beginningless time we've been
              living in an endless dream the dream was but the dreaming was
              real What is it that is aware of the mind And can you find it Do you
              realize what you have inside of you"`}
            />

          </div>

          <div className="video2" style={{
            width: "70%",
            height: height2 + "vw",
            position: "relative",
            marginTop: "160px",
          }}>

            <VideoPlayer id="2"
              src={peterDofound}
              copy={`PETER DO "Foundation"`}
            />

          </div>

          <div className="video4" style={{
            width: "50%",
            height: height4 + "vw",
            position: "relative",
            marginTop: height2 / 2 + "vw",
            marginLeft: "50%"
          }}>

            <VideoPlayer id="3"
              src={ssense}
              copy={`SSENSE article "High Sobriety" `}


            />

          </div>

          <div className="video3" style={{
            width: "100%",
            height: height3 + "vw",
            position: "relative",
            marginTop: height4 + "vw",
            marginLeft: "0"
          }}>

            <VideoPlayer id="4"
              src={peterDonight}
              copy={` PETER DO "NIGHT"`}
            />

          </div>
          {/* <div
            className="video2"
            style={{
              marginTop: "5px",
              marginLeft: "5px",
              width: "100%",
              height: "100%",
              marginTop: "50px",
            }}
          > </div> */}
        </div>
        <div style={{ display: "flex", flexDirection: "row", position: "relative", width: "100vw" }}>
          <p className="info" style={{
            width: "220px",
            position: "relative",
            marginTop: "160px",
            marginLeft: "1rem"
          }}>
            Steph Shiu is a Chinese-American model, musician and multi-disciplinary artist born and raised in Vancouver, Canada. She has worked with  Vogue, Double Magazine, Shiseido, Estée Lauder , Ralph Lauren to name a few and walked for top international designers including Fendi, Peter Do, Jean Paul Gaultier, Eckhaus Latta, Telfar, Trussardi, Sunnei, and Balmain;
            <br /><br />
            Beyond modeling, Steph is an accomplished musician, who is signed to the  record label, Breeze. As a creative, music was always her first love. Beginning her training with classical and jazz percussion, Steph is now a multi-instrumentalist — from piano to bass and electric guitars as well as Ableton. Her practice is focused on mixing spiritual healing frequencies with pop, experimental ambient and electronic genres. Steph has worked on client commissions for Peter Do, Vogue, Public Hotels, UNIQLO, and SSENSE.
            <br /><br />
            Steph has developed her own art objects and accessories label, (H)OUR OBJECTS, H(OUR) combines Steph’s spiritual background with a passion for Anime, Science-Fiction, and Italian Modernist Design. Channeling her meditative practice in the creation of deeply meaningful and artisanal talispersons for the home and the body. H(OUR) has been featured in publications like DAZED and Interview Magazine.
            <br /><br />
            In her time away from work, she practices Kadampa meditation and loves to cook for her friends and family. Steph is based in New York City.
          </p>
          <h2 className="info" style={{
            width: "130px",
            position: "relative",
            marginTop: "160px",
            marginRight: "1rem",
            marginLeft: "auto",
            fontSize: 16,
            fontWeight: "normal",
            textTransform: "uppercase"
          }}>
            CLIENTS <br /><br />Telfar <br />Eckhaus Latta<br />Vogue China <br />SSENSE<br />Sunnei <br /> Jean Paul Gaultier<br />Peter Do<br />Rag & Bone<br /> Area<br />Rachel Comey<br />Mac Cosmetics<br />Shiseido<br />Saks Fifth Avenue<br />Nike<br />Puss Puss Magazine<br />Numero<br />ELLE<br />Prabal Gurung<br />Fendi<br />Trussardi
          </h2>
        </div>
      </>
    );
  };
  render = () => {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact={true} path="/" element={this.renderHomeScreen()} />
          </Routes>
          {/* <Route exact={true} path="/menu/" render={this.renderHomeScreenNav} />
          <Route exact={true} path="/about/" render={this.renderAbout} />
          <Route exact={true} path="/CTM/" render={this.renderCtm} /> */}
        </BrowserRouter>
      </div>
    );
  };
}

export default App;
